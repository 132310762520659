if (typeof Object.assign !== 'function') {
  // eslint-disable-next-line no-unused-vars
  Object.assign = function assign(target) {
    // .length of function is 2

    if (target == null) {
      // TypeError if undefined or null
      throw new TypeError('Cannot convert undefined or null to object')
    }

    const to = Object(target)

    for (let index = 1; index < arguments.length; index++) {
      // eslint-disable-next-line prefer-rest-params
      const nextSource = arguments[index]

      if (nextSource != null) {
        // Skip over if undefined or null
        // eslint-disable-next-line no-restricted-syntax
        for (const nextKey in nextSource) {
          // Avoid bugs when hasOwnProperty is shadowed
          if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
            to[nextKey] = nextSource[nextKey]
          }
        }
      }
    }
    return to
  }
}
